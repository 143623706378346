<template>
  <div>
    <validation-observer ref="accountRules" tag="form">
      <b-form class="mt-1" @submit.prevent>
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">
              Data Pegawai
            </h5>
          </b-col>
          <b-col md="6">
            <b-form-group label="NISN" label-for="student_nisn">
              <validation-provider #default="{ errors }" name="NISN" rules="required">
                <b-form-input id="nisn" v-model="dataParent.nisn" type="number"
                  :state="errors.length > 0 ? false : null" placeholder="9976404XXX" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="Hubungan Orang Tua" rules="required">
              <b-form-group label="Hubungan Orang Tua" label-for="mother_parental_status"
                :state="errors.length > 0 ? false : null">
                <v-select id="mother_parental_status" v-model="dataParent.mother_parental_status"
                  :reduce="(status) => status.value" placeholder="Pilih Status Orang Tua"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="dataParentStatus" label="text" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="Pendidikan Orang Tua" rules="required">
              <b-form-group label="Pendidikan Orang Tua" label-for="mother_parental_education"
                :state="errors.length > 0 ? false : null">
                <v-select id="mother_parental_education" v-model="dataParent.mother_parental_education"
                  :reduce="(status) => status.value" placeholder="Pilih Pendidikan Orang Tua"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="dataParentEducation" label="text" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-group label="Nama Orangtua" label-for="name">
              <validation-provider #default="{ errors }" name="Nama Orangtua" rules="required">
                <b-form-input id="name" v-model="dataParent.name" :state="errors.length > 0 ? false : null"
                  placeholder="Jhon Doe" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Tempat Lahir" label-for="birth_place">
              <validation-provider #default="{ errors }" name="Tempat Lahir" rules="required">
                <b-form-input id="birth_place" v-model="dataParent.birth_place"
                  :state="errors.length > 0 ? false : null" placeholder="Yogyakarta" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Tanggal Lahir" label-for="birth_date">
              <validation-provider #default="{ errors }" name="Tanggal Lahir" rules="required">
                <b-form-input id="birth_date" v-model="dataParent.birth_date" type="date"
                  :state="errors.length > 0 ? false : null" placeholder="21-05-1992" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="Agama" rules="required">
              <b-form-group label="Agama" label-for="religion" :state="errors.length > 0 ? false : null">
                <v-select id="religion" v-model="dataParent.religion" :reduce="(religion) => religion.value"
                  placeholder="Pilih Agama" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="dataReligion"
                  label="text" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="Kewarganegaraan" rules="required">
              <b-form-group label="Kewarganegaraan" label-for="nationality" :state="errors.length > 0 ? false : null">
                <v-select id="nationality" v-model="dataParent.nationality" :reduce="(nationality) => nationality.name"
                  placeholder="Pilih Kewarganegaraan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="dataCountries" label="name" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-group label="Program Studi" label-for="education_major">
              <validation-provider #default="{ errors }" name="education_major" rules="required">
                <b-form-input id="education_major" v-model="dataParent.education_major"
                  :state="errors.length > 0 ? false : null" placeholder="Sistem Informasi" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Pekerjaan" label-for="profession">
              <validation-provider #default="{ errors }" name="Pekerjaan" rules="required">
                <b-form-input id="profession" v-model="dataParent.profession" :state="errors.length > 0 ? false : null"
                  placeholder="Karyawan Swasta" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Penghasilan" label-for="salary">
              <validation-provider #default="{ errors }" name="Pekerjaan" rules="Penghasilan">
                <b-form-input id="salary" v-model="dataParent.salary" :state="errors.length > 0 ? false : null"
                  placeholder="10000000" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="Provinsi" rules="required">
              <b-form-group label="Provinsi" label-for="province" :state="errors.length > 0 ? false : null">
                <v-select id="province" v-model="locations.province" placeholder="Pilih Provinsi"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="dataProvinces" label="name" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="Kabupaten/Kota" rules="required">
              <b-form-group label="Kabupaten/Kota" label-for="city" :state="errors.length > 0 ? false : null">
                <v-select id="city" v-model="locations.city" placeholder="Pilih Kabupaten/Kota"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="filterCities" label="name" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="Kecamatan" rules="required">
              <b-form-group label="Kecamatan" label-for="distric" :state="errors.length > 0 ? false : null">
                <v-select id="distric" v-model="locations.distric" placeholder="Pilih Kecamatan"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="filterDistrics" label="name" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="Kelurahan/Desa" rules="required">
              <b-form-group label="Kelurahan/Desa" label-for="subdistric" :state="errors.length > 0 ? false : null">
                <v-select id="subdistric" v-model="locations.subdistric" placeholder="Pilih Kelurahan/Desa"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="filterSubDistrics" label="name" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-group label="RW" label-for="rw">
              <validation-provider #default="{ errors }" name="rw" rules="required">
                <b-form-input id="rw" v-model="dataParent.rw" :state="errors.length > 0 ? false : null"
                  placeholder="11" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="RT" label-for="rt">
              <validation-provider #default="{ errors }" name="rt" rules="required">
                <b-form-input id="rt" v-model="dataParent.rt" :state="errors.length > 0 ? false : null"
                  placeholder="21" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Alamat" label-for="address">
              <validation-provider #default="{ errors }" name="address" rules="required">
                <b-form-input id="address" v-model="dataParent.address" :state="errors.length > 0 ? false : null"
                  placeholder="Jl. Kaliurang Km. 7 No.B5" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="Status Orang Tua" rules="required">
              <b-form-group label="Status Orang Tua" label-for="mother_parental_status"
                :state="errors.length > 0 ? false : null">
                <v-select id="mother_parental_status" v-model="dataParent.mother_parental_status"
                  :reduce="(status) => status.value" placeholder="Pilih Status dalam keluarga"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="dataParentStatus" label="text" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-group label="No. Handphone" label-for="handphone">
              <validation-provider #default="{ errors }" name="No. Handphone" rules="required">
                <b-form-input id="handphone" v-model="dataParent.handphone" :state="errors.length > 0 ? false : null"
                  placeholder="08xxxxxxxxx" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Email" label-for="email">
              <validation-provider #default="{ errors }" name="Email" rules="required">
                <b-form-input id="email" v-model="dataParent.email" :state="errors.length > 0 ? false : null"
                  placeholder="devyferd1223@gmail.com" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="NIK" label-for="nik">
              <validation-provider #default="{ errors }" name="nik" rules="required">
                <b-form-input id="nik" v-model="dataParent.nik" :state="errors.length > 0 ? false : null"
                  placeholder="devyferd1223@gmail.com" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- submit and reset -->
          <b-col offset-md="4">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
              @click="formSubmitted">
              Submit
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
              @click="() => $router.go(-1)">
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm, BButton,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'
// import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BButton,
  },
  data() {
    return {
      required,
      email,
      size,
      dataCountries,
      dataProvinces,
      dataCities,
      dataDistrics,
      dataSubDistrics,
      dataParent: {
        student_nisn: '',
        relationship: '',
        education_level_id: '',
        name: '',
        birth_place: '',
        birth_date: '',
        religion: '',
        nationality: '',
        education_major: '',
        profession: '',
        salary: '',
        province: '',
        city: '',
        district: '',
        subdistrict: '',
        rw: '',
        rt: '',
        address: '',
        parental_status: '',
        handphone: '',
        email: '',
        nik: '',
      },
      locations: {
        province: '',
        city: '',
        distric: '',
        subdistric: '',
      },     
      dataGender: [
        { value: 'M', text: 'Laki-laki' },
        { value: 'F', text: 'Perempuan' },
      ],
      dataReligion: [
        { value: 1, text: 'Islam' },
        { value: 2, text: 'Protestan' },
        { value: 3, text: 'Katolik' },
        { value: 4, text: 'Hindu' },
        { value: 5, text: 'Buddha' },
        { value: 6, text: 'Khonghucu' },
      ],
      dataReadingQuran: [
        { value: 'lancar', text: 'Lancar' },
        { value: 'belum_lancar', text: 'Belum Lancar' },
        { value: 'belum_bisa', text: 'Belum Bisa' },
      ],
      dataEducation: [{ value: 1, text: 'SD/MI' }],
      dataParentStatus: [
        { value: '1', text: 'Ayah' },
        { value: '2', text: 'Ibu' },
        { value: '3', text: 'Wali' },
      ],
      dataParentEducation: [
        { value: '1', text: 'SD' },
        { value: '2', text: 'SMP' },
        { value: '3', text: 'SMA' },
        { value: '4', text: 'S1' },
        { value: '5', text: 'S2' },
        { value: '6', text: 'S3' },
      ],
      dataMutations: [
        { value: 'Y', text: 'Ya' },
        { value: 'N', text: 'Tidak' },
      ],
      dataClasss: [{ value: '4nvoeygeoxw3zpra', text: 'VII' }],
    }
  },
  computed: {
    filterCities() {
      return this.dataCities.filter(
        item => item.parent === this.locations.province.wilayah_id,
      )
    },
    filterDistrics() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.city.wilayah_id,
      )
    },
    filterSubDistrics() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.distric.wilayah_id,
      )
    },
    filterCitiesFather() {
      return this.dataCities.filter(
        item => item.parent === this.locations.father_province.wilayah_id,
      )
    },
    filterCitiesMother() {
      return this.dataCities.filter(
        item => item.parent === this.locations.mother_province.wilayah_id,
      )
    },
    filterDistricsFather() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.father_city.wilayah_id,
      )
    },
    filterDistricsMother() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.mother_city.wilayah_id,
      )
    },
    filterSubDistricsFather() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.father_distric.wilayah_id,
      )
    },
    filterSubDistricsMother() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.mother_distric.wilayah_id,
      )
    },
  },
  methods: {
    async formSubmitted() {
      try {
        const dataFormParent = new FormData()
        Object.keys(this.dataParent).forEach(key => {
          dataFormParent.append(key, this.dataParent[key])
        })
        dataFormParent.append('province', this.locations.province.name)
        dataFormParent.append('city', this.locations.city.name)
        dataFormParent.append('district', this.locations.distric.name)
        dataFormParent.append('subdistrict', this.locations.subdistric.name)
        await this.$http.post('/studentfamilies', dataFormParent)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Orangtua Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'data-orangtua' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Orangtua Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
